var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('vue-good-table',{attrs:{"columns":_vm.columns,"pagination-options":{
      enabled: true,
      perPage: 20,
      perPageDropdown: [10, 20, 50],
      nextLabel: _vm.texts.nextLabel,
      prevLabel: _vm.texts.prevLabel,
      rowsPerPageLabel: _vm.texts.rowsPerPageLabel,
      ofLabel: _vm.texts.ofLabel,
      pageLabel: _vm.texts.pageLabel, // for 'pages' mode
      allLabel: _vm.texts.allLabel,
    },"rows":_vm.groupStats,"search-options":{
      enabled: true,
      skipDiacritics: true,
      placeholder: _vm.texts.searchLabel
    },"sort-options":{
      enabled: true,
      initialSortBy: [{field: 'name', type: 'asc'}]
    },"theme":"melos"},scopedSlots:_vm._u([{key:"emptystate",fn:function(){return [_c('div',{staticClass:"u-m-size-40 c-backend__nothing-to-show"},[_c('div',{staticClass:"c-typo__h3 c-backend__nothing-to-show"},[_c('icon',{attrs:{"classes":"far fa-2x fa-folder-open"}}),_c('span',[_vm._v(_vm._s(_vm.$t('common.nothingToShow')))])],1)])]},proxy:true},{key:"table-row",fn:function(props){return [(props.column.field ==='logo')?_c('span',[_c('div',{staticClass:"vgt-icon",style:(`background-image: url('${props.row.logo.thumb}');`)})]):(props.column.field ==='manufacturers')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field].active)+" / "+_vm._s(props.formattedRow[props.column.field].count)+" ")]):(props.column.field ==='distributors')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field].active)+" / "+_vm._s(props.formattedRow[props.column.field].count)+" ")]):(props.column.field ==='employees')?_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field].active)+" / "+_vm._s(props.formattedRow[props.column.field].count)+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('button-base',{attrs:{"id":"export-all-projects","label":"Export all projects","on-click-action":() => { return _vm.exportAll() }}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }