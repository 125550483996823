<template>
  <div>
    <vue-good-table
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: 20,
        perPageDropdown: [10, 20, 50],
        nextLabel: texts.nextLabel,
        prevLabel: texts.prevLabel,
        rowsPerPageLabel: texts.rowsPerPageLabel,
        ofLabel: texts.ofLabel,
        pageLabel: texts.pageLabel, // for 'pages' mode
        allLabel: texts.allLabel,
      }"
      :rows="groupStats"
      :search-options="{
        enabled: true,
        skipDiacritics: true,
        placeholder: texts.searchLabel
      }"
      :sort-options="{
        enabled: true,
        initialSortBy: [{field: 'name', type: 'asc'}]
      }"
      theme="melos"
    >
      <template #emptystate>
        <div
          class="u-m-size-40 c-backend__nothing-to-show"
        >
          <div class="c-typo__h3 c-backend__nothing-to-show">
            <icon classes="far fa-2x fa-folder-open"/>
            <span>{{ $t('common.nothingToShow') }}</span>
          </div>
        </div>
      </template>

      <template #table-row="props">
        <span v-if="props.column.field ==='logo'">
          <div
            :style="`background-image: url('${props.row.logo.thumb}');`"
            class="vgt-icon"
          />
        </span>

        <span v-else-if="props.column.field ==='manufacturers'">
          {{ props.formattedRow[props.column.field].active }} / {{ props.formattedRow[props.column.field].count }}
        </span>

        <span v-else-if="props.column.field ==='distributors'">
          {{ props.formattedRow[props.column.field].active }} / {{ props.formattedRow[props.column.field].count }}
        </span>

        <span v-else-if="props.column.field ==='employees'">
          {{ props.formattedRow[props.column.field].active }} / {{ props.formattedRow[props.column.field].count }}
        </span>

        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <button-base
      id="export-all-projects"
      label="Export all projects"
      :on-click-action="() => { return exportAll() }"
    />
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'
import { mapGetters } from 'vuex'

import MixinDialogHandler from '@/mixins/dialogHandler'
import Icon from '@/components/common/Icon'
import { apiMethods } from '@/helper/apiHelper'
import download from 'downloadjs'
import ButtonBase from '@/components/common/ButtonBase.vue'

/**
 * @displayName GroupStats List Component (for Admin)
 */
export default {
  name: 'AdminGroupStatsList',
  components: {
    ButtonBase,
    Icon,
    VueGoodTable
  },
  mixins: [MixinDialogHandler],
  data () {
    return {
      columns: [
        {
          field: 'logo',
          sortable: false,
          html: true,
          tdClass: 'vgt-icon-container',
          thClass: 'vgt-no-min-width'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.name,
          field: 'name'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.measurement,
          field: 'measurement'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.manufacturers,
          field: 'manufacturers',
          sortable: false
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.distributorGroups,
          field: 'distributor_groups',
          sortable: false
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.distributors,
          field: 'distributors',
          sortable: false
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.employees,
          field: 'employees',
          sortable: false
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.locations,
          field: 'locations',
          type: 'number'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.projectsInProgress,
          field: 'projects.inProgress',
          type: 'number'
        },
        {
          label: this.$i18n.messages[this.$i18n.locale].backend.stats.group.admin.tableLabel.projectsCalculated,
          field: 'projects.calculated',
          type: 'number'
        }
      ],
      test: null
    }
  },
  computed: {
    ...mapGetters({
      getToken: 'backendAPI/getToken',
      groupStats: 'backendAPI/stats/getAllGroupStats'
    }),
    texts () {
      const backend = this.$i18n.messages[this.$i18n.locale].backend
      const pagination = this.$i18n.messages[this.$i18n.locale].backend.pagination
      return {
        nextLabel: pagination.nextLabel,
        prevLabel: pagination.prevLabel,
        rowsPerPageLabel: pagination.rowsPerPageLabel,
        ofLabel: pagination.ofLabel,
        pageLabel: pagination.pageLabel,
        allLabel: pagination.allLabel,

        searchLabel: backend.search
      }
    }
  },
  methods: {
    async exportAll () {
      await apiMethods.request('get', `${process.env.VUE_APP_API_URL}/api/stats/exportall`, null, this.getToken)
        .then(response => {
          const data = response.data
          const headers = Object.keys(data[0]).join(',')
          const rows = data.map(row => {
            return Object.values(row).map(value => {
              if (typeof value === 'string') {
                return `"${value.replace(/"/g, '""')}"`
              }
              return value
            }).join(',')
          })
          const csvContent = [headers, ...rows].join('\n')
          download(csvContent, 'all.csv', 'text/csv')
        }).catch(error => {
          console.log(error)
        })
    }
  }
}
</script>
